.badge {
  padding: 0.5rem;
}

.badge-pill {
  padding-bottom: 0.4em;
}

.card {
  border: 1px solid #efefef;
}

.large-blue-text {
  color: $header-primary-colour;
  font-size: 30px;
  font-weight: $font-weight-bold;
  text-align: center;
}

.medium-blue-text {
  color: $header-primary-colour;
  font-size: 20px;
  font-weight: $font-weight-bold;
  text-align: center;
}

.text-warning {
  color: $warning !important;
}

.circle-initials {
  background: $hazy-orange;
  border-radius: 50%;
  color: $white;
  font-size: 16px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  width: 34px;
}

.circle-initials.small {
  border-radius: 50%;
  color: $white;
  font-size: 15px;
  height: 30px;
  line-height: 31px;
  text-align: center;
  width: 30px;
}

.ql-editor {
  min-height: 120px;

  p,
  dl,
  ol,
  ul,
  blockquote,
  table {
    font-family: $default-font;
  }

  ol,
  ul {
    list-style-position: outside;
    margin: 1em 0 0 2em !important;
    padding-left: 0 !important;

    li:not(.ql-direction-rtl) {
      padding-left: 0 !important;

      &::before {
        display: none;
      }
    }
  }

  ul {
    li {
      list-style-type: disc !important;
    }
  }
}

.dashboard-menu-toggle {
  h5 {
    color: $header-primary-colour;
    font-size: 1.15em;
    font-weight: $font-weight-bold;
  }
}

ul.admin-nav {
  display: none;

  &.is-open {
    display: block;
  }

  li {
    margin: 0.25em 0;
    overflow: hidden;

    a {
      background-color: transparent;
      border-radius: 4px;
      color: $primary-colour;
      display: block;
      padding: 0.5em;

      &:hover {
        background-color: #ddddddbd;
        color: $secondary-colour;
        text-decoration: none;
      }
    }

    &.active {
      a {
        background-color: #ddddddbd;
        color: $secondary-colour;
        font-weight: $font-weight-bold;
      }
    }
  }
}

.vacancy-template-form {
  .required {
    display: none;
  }
}

.vacancy-heading-badge {
  font-size: 0.5em;
  margin-left: 0.5em;
  position: relative;
  top: -1em;
}

.fa-star {
  font-size: 20px;

  &.checked {
    color: $yellow;
  }
}

.hidden {
  display: none !important;
}

.status-badges {
  align-items: center;
  display: flex;

  .outcome-icon-success {
    font-size: 10px;
    color: white;
    position: relative;
    top: -1px;
  }

  .outcome-icon-failed {
    font-size: 7px;
    color: #d82b34;
    position: relative;
    top: -1px;
  }

  .outcome-icon-mid {
    font-size: 12px;
    color: $yellow;
    position: relative;
    top: -1px;
  }

  a {
    .status-badge {
      margin-right: 0.25em;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.warning-icon {
  position: relative;
  top: -1px;
}

.mapped-answers.section-toggle-header + .section-toggle-body {
  border-bottom: 1px solid $hover-grey;
  margin-bottom: 1em;

  &:last-child {
    border: 0;
    margin-bottom: 0;
  }
}

.heading-note {
  color: $primary-colour;
  font-size: 0.75em;
  font-weight: $font-weight-normal;
  margin-left: 2em;
}

.external-candidate-view > .col > div > div > .react-pdf__Page__canvas {
  display: inline !important;
}

.stage-input-group {
  .stage-input-group-col {
    align-items: center;
    display: flex;

    .dd-menu {
      .scroll-wrapper {
        padding: 10px;
      }
    }
  }

  .stage-input-controls {
    align-items: center;
    display: flex;
  }

  .invalid-feedback {
    margin-left: 17%;
  }

  .form-select {
    font-size: 0.875rem;
    height: calc(1.5em + 0.75rem + 4px);
    background-image: none;
  }
}

.pt-7px {
  padding-top: 7px !important;
}

.res-user > .checkbox > label {
  margin-bottom: 0;
  font-weight: bold;
}

.res-user > p {
  margin-top: 0;
  margin-bottom: 10px;
}

@media print {
  .app-header,
  #side-bar,
  .reporting-filters {
    display: none !important;
  }
  .app-body {
    margin-top: 0 !important;
  }
  .recharts-wrapper,
  .recharts-surface,
  .recharts-legend-wrapper {
    width: 100% !important;
  }
  .recharts-legend-item > svg {
    width: auto !important;
    display: inline-block !important;
    right: inherit !important; /* align to left */
  }
  .page-break {
    page-break-after: always;
  }
  .reporting-charts {
    overflow: hidden;
  }
}

.login-box-bg {
  background-color: rgb(247, 247, 247);
  box-shadow: 1px 1px #9f9f9fff;
}

.workflow-editor {
  input[type='checkbox'] + span {
    top: 1px;
  }
}

.vacancy-header-actions {
  margin-left: -20px;
  margin-right: -20px;
  padding-bottom: 2.5rem;
}

.iframe-loading-wrapper {
  width: 48%;
  z-index: 1;
}

.iframe-error-message {
  width: 48%;
}

.is-edited .react-select__control {
  border-color: red !important;
}

@keyframes pulsate {
  0% {
    color: red;
  }
  50% {
    color: black;
  }
  100% {
    color: red;
  }
}

.microphone-icon {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 9px;
  right: 45px;
}

.microphone-icon.active {
  color: black;
  animation: pulsate 1s infinite;
}

.voice-effect-ring {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 122, 255, 0.5);
  border-radius: 50%;
  animation: ringEffect 1.5s infinite;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

@keyframes ringEffect {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.expanded-tooltip {
  max-width: 400px !important; /* Adjust this value as needed */
  width: 90vw !important; /* This ensures the tooltip is responsive on smaller screens */
}

.expanded-intro-content {
  width: 100%;
  padding: 10px;
}

.expanded-intro-content img {
  max-width: 100%;
  height: auto;
}

.intro-tooltip {
  max-width: 400px !important;
  width: 90vw !important;
  border-radius: 3px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #e0e0e0 !important;
  padding: 20px !important;
}

.intro-step {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

.intro-title {
  font-size: 18px;
  color: #333;
  margin-bottom: 15px;
  font-weight: 600;
}

.intro-step p {
  font-size: 14px;
  line-height: 1.5;
  color: #666;
  margin-bottom: 10px;
}

.intro-step ul {
  margin-left: 20px;
  margin-bottom: 15px;
  padding-left: 0;
}

.intro-step li {
  margin-bottom: 5px;
  font-size: 14px;
  color: #666;
  list-style-type: disc;
}

.introjs-button {
  background-color: #f5f5f5 !important;
  color: #333 !important;
  border: 1px solid #ccc !important;
  padding: 6px 12px !important;
  border-radius: 3px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  text-shadow: none !important;
  transition: background-color 0.3s ease !important;
}

.introjs-button:hover {
  background-color: #e8e8e8 !important;
}

.introjs-button:focus {
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25) !important;
}

.introjs-skipbutton {
  color: #999 !important;
}

.introjs-prevbutton {
  margin-right: 5px !important;
}

.introjs-tooltiptext {
  padding: 0 !important;
}

.feedback-textarea {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #333;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:focus {
    outline: none;
    border-color: #66afe9;
    box-shadow: 0 0 0 0.2rem rgba(102, 175, 233, 0.25);
  }

  &:disabled {
    background-color: #f5f5f5;
    border-color: #ddd;
    color: #888;
    cursor: not-allowed;
  }
}

.intro-tooltip-wide {
  max-width: 500px !important;
  min-width: 500px !important;
}
